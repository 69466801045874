(function tpTableGarantieMecanique() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'tp_table-garantie-mecanique';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }

  const accessibleArrayNavigation = [
    'véhicules neufs et occasions de moins de 5 ans',
    'véhicules occasions de plus de 5 ans',
    'campings-cars neufs et occasions de moins de 7 ans',
    'campings-cars occasions de plus de 7 ans',
  ];

  $(() => {
    $('.tp-table-garantie-mecanique__container__slider-container').slick({
      autoplay: false,
      autoplaySpeed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow:
        '<button class="slick-prev" type="button"><span class="i-arrow-left-circle"></span><span class="slick-sr-only">Slide précédente</span></button>',
      nextArrow:
        '<button class="slick-next" type="button"><span class="i-arrow-right-circle"></span><span class="slick-sr-only">Slide suivante</span></button>',
      dots: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            arrows: true,
          },
        },
      ],
      customPaging: (slider, i) =>
        $(
          `<button type="button"><span class="slick-dot-icon" aria-label="Slide ${
            i + 1
          }"></span><span class="slick-sr-only">Garanties mécaniques pour ${
            accessibleArrayNavigation[i]
          }</span></button>`,
        ),
    });
  });
})();
