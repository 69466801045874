(function tpTaegTable() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'tp_taeg-table';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }
  const getTodayDate = GlobalSite.checkDependency('GlobalSite.getTodayDate');
  const getCrDataForTable = GlobalSite.checkDependency('GlobalSite.getCrDataForTable');
  const getTemplateTable = (dataTable) =>
    dataTable.map(
      (
        item,
      ) => `<tr><td>De ${item.minDuration} à ${item.maxDuration}<br><span class="text-light">mois / max</span></td>
              <td>${item.taeg1} %</td>
               <td>${item.taeg2} %</td>
               </tr>`,
    );
  $(async () => {
    const dataTable = await getCrDataForTable();
    if (dataTable.length === 0) {
      Log.log({ message: 'error load formulas list' });
      return;
    }
    $('#date-now').html(getTodayDate());
    $('#cr-taeg-formulas').append(getTemplateTable(dataTable));
  });
})();
