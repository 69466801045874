(function tpBlocAvantage() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'tp_bloc-avantage';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }

  const ReadMore = GlobalSite.checkDependency('GlobalSite.readMore');
  const readMoreInstance = new ReadMore();
  $(() => {
    readMoreInstance.domHandler();
  });
})();
